:root {
  --purp: #524763;
  --darkPurp: #373142;
  --xtraPurp: #191324;
  --grey: #d8d8d8;
  --teal: #82d8d8;
  --red: #e54b4b;
  --black: #333;
  --black-20: #4f4f4f;
  --black-40: #828282;
  --black-60: #bdbdbd;
  --white-60: #e0e0e0;
  --white-40: #f2f2f2;
  --white-20: #f5f4f6;
  --white: #fff;
}


.nav-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 100px;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  background: var(--white);
  z-index: 10;
}

.nav-wrapper a {
  display: block;
  text-align: left;
  font-size: 4rem;
  color: black;
  text-decoration: none;
  transition: 0.3s ease border;
  border-bottom: solid 4px transparent;
}

.nav-wrapper a:hover {
  border-bottom: solid 4px var(--teal);
}
